import React from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Faq3 = () => {
  return (
    <>
      <Layout>
        <Container>
          <Row>
            <div className="back-button mb-4 mt-3">
              <Link to="/faq/">&larr; Back</Link>
            </div>
            <Col sm="12">
              <div className="mb-5">
                <StaticImage
                  src="../../images/woman-on-pink-background.jpg"
                  alt="Woman smiling"
                  placeholder="none"
                  layout="fullWidth"
                />
                <h2 className="mt-3">
                  How much do Lumineers<sup>&reg;</sup> cost?
                </h2>
                <p>
                  On average, Lumineers<sup>&reg;</sup> can cost anywhere
                  between US$800 and US$2,000 per tooth depending on the unique
                  oral health situation of each patient. The final cost of any
                  dental procedure is determined in consultation with a licensed
                  dentist. Many factors may affect your final financial
                  decision. Dentists may or may not offer patient financing
                  services or payment plans. Cosmetic dental treatment may or
                  may not be covered by insurance. While you consider these
                  factors, it is equally important to remember that Lumineers
                  are a long-term investment in you. Patients have reported
                  improvements in self-confidence, happiness and overall health.
                  Lumineers may last many years with proper dental care. Ask
                  your dentist if Lumineers are right for you. If you do not
                  have a dentist, view our growing directory of select dentists
                  that may be in your area.
                </p>
              </div>
              <div className="mb-5 text-center">
                <Link to="../../find-a-dentist/" className="intro-button mb-5">
                  Find a Dentist
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Faq3
